import React, { useState } from "react"
import $ from "jquery";
import { Fade } from "react-awesome-reveal"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


const AboutInvestitionGallery = () => {
    const [modalOpen, setModalOpen] = useState(false),
          [itemNumber, setItemNumber] = useState(0);

    const galleryImages = [
        require('../../assets/img/gallery1.jpg'),
        require('../../assets/img/gallery2.jpg'),
        require('../../assets/img/gallery4.jpg'),
        require('../../assets/img/gallery3.jpg'),
        require('../../assets/img/gallery5.jpg'),
        require('../../assets/img/gallery8.jpg'),
        require('../../assets/img/gallery6.jpg'),
        require('../../assets/img/gallery9.jpg'),
        require('../../assets/img/gallery7.jpg'),
    ];

    const galleryOpen = item => {
        setModalOpen(true);
        setItemNumber(item);
        $('body').addClass('overflow-hidden');

    }

    return (
        <section className="about-investition-gallery">
            <div className="container">
                <div className="row">
                    <div className="col-6 offset-1 about-investition-gallery__title-holder">
                        <h2 className="about-investition-gallery__title">
                            Galeria
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                    </div>
                </div>
                <div className="row about-investition-gallery__image-container">
                    <div className="col-12 col-lg-8 image-container__left">
                        <div className="about-investition-gallery__row">
                            <Fade delay={150} direction={"left"} triggerOnce>
                                <div className="about-investition-gallery__image-holder row__item about-investition-gallery__image-holder--first">
                                    <button
                                        onClick={ () => galleryOpen(0) }
                                        className="about-investition-gallery__image-button" 
                                    >
                                        <div
                                            className="image-button__image"
                                            style={{ background: `url(${ galleryImages[0] }) center no-repeat`, backgroundSize: 'cover' }}>
                                        </div>
                                    </button>
                                </div>
                            </Fade>
                            <Fade delay={250} direction={"bottom"} triggerOnce>
                                <div className="about-investition-gallery__image-holder row__item about-investition-gallery__image-holder--second">
                                    <button
                                        onClick={ () => galleryOpen(1) }
                                        className="about-investition-gallery__image-button"
                                    >
                                        <div
                                            className="image-button__image"
                                            style={{ background: `url(${ galleryImages[1] }) center no-repeat`, backgroundSize: 'cover' }}>
                                        </div>
                                    </button>
                                </div>
                            </Fade>
                        </div>
                        <Fade delay={500} direction={"left"} triggerOnce>
                            <div className="about-investition-gallery__image-holder row__item about-investition-gallery__image-holder--third">
                                <button
                                    onClick={ () => galleryOpen(2) }
                                    className="about-investition-gallery__image-button"
                                    
                                >
                                    <div
                                        className="image-button__image"
                                        style={{ background: `url(${ galleryImages[2] }) center no-repeat`, backgroundSize: 'cover' }}>
                                    </div>
                                </button>
                            </div>
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-4 image-container__right">
                        <Fade delay={750} direction={"right"} triggerOnce>
                            <div className="about-investition-gallery__image-holder row__item about-investition-gallery__image-holder--fourth">
                                <button
                                    onClick={ () => galleryOpen(3) }
                                    className="about-investition-gallery__image-button"
                                >
                                    <div
                                        className="image-button__image"
                                        style={{ background: `url(${ galleryImages[3] }) center no-repeat`, backgroundSize: 'cover' }} >
                                    </div>
                                </button>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="row about-investition-gallery__second-row">
                    <div className="col-12 col-lg-3 about-investition-gallery__col">
                        <Fade delay={150} direction={"left"} triggerOnce>
                            <div className="about-investition-gallery__image-holder row__item about-investition-gallery__image-holder--fifth">
                                <button
                                    onClick={ () => galleryOpen(4) }
                                    className="about-investition-gallery__image-button"
                                >
                                    <div
                                        className="image-button__image"
                                        style={{ background: `url(${ galleryImages[4] }) center no-repeat`, backgroundSize: 'cover' }} >
                                    </div>
                                </button>
                            </div>
                        </Fade>
                        <Fade delay={250} direction={"top"} triggerOnce>
                            <div className="about-investition-gallery__image-holder row__item about-investition-gallery__image-holder--sixth">
                                <button
                                    onClick={ () => galleryOpen(5) }
                                    className="about-investition-gallery__image-button"
                                >
                                    <div
                                        className="image-button__image"
                                        style={{ background: `url(${ galleryImages[5] }) center no-repeat`, backgroundSize: 'cover' }} >
                                    </div>
                                </button>
                            </div>
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-3 about-investition-gallery__col">
                        <Fade delay={500} direction={"bottom"} triggerOnce>
                            <div className="about-investition-gallery__image-holder row__item about-investition-gallery__image-holder--seventh">
                                <button
                                    onClick={ () => galleryOpen(6) }
                                    className="about-investition-gallery__image-button"
                                >
                                    <div
                                        className="image-button__image"
                                        style={{ background: `url(${ galleryImages[6] }) center no-repeat`, backgroundSize: 'cover' }} >
                                    </div>
                                </button>
                            </div>
                        </Fade>
                        <Fade delay={750} direction={"top"} triggerOnce>
                            <div className="about-investition-gallery__image-holder row__item about-investition-gallery__image-holder--eight">
                                <button
                                    onClick={ () => galleryOpen(7) }
                                    className="about-investition-gallery__image-button"
                                >
                                    <div
                                        className="image-button__image"
                                        style={{ background: `url(${ galleryImages[7] }) center no-repeat`, backgroundSize: 'cover' }} >
                                    </div>
                                </button>
                            </div>
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-6">
                        <Fade delay={750} direction={"right"} triggerOnce>
                            <div className="about-investition-gallery__image-holder row__item about-investition-gallery__image-holder--ninth">
                                <div className="about-investition-gallery__image-holder about-investition-gallery__image-holder--nineth">
                                    <button
                                        onClick={ () => galleryOpen(8) }
                                        className="about-investition-gallery__image-button"
                                    >
                                        <div
                                            className="image-button__image"
                                            style={{ background: `url(${ galleryImages[8] }) center no-repeat`, backgroundSize: 'cover' }} >
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>

            {modalOpen && (
                <Lightbox
                    mainSrc={galleryImages[itemNumber]}
                    nextSrc={galleryImages[(itemNumber + 1) % galleryImages.length]}
                    prevSrc={galleryImages[(itemNumber + galleryImages.length - 1) % galleryImages.length]}
                    onCloseRequest={() => {setModalOpen(false); $('body').removeClass('overflow-hidden'); }}
                    onMovePrevRequest={() =>
                        setItemNumber((itemNumber + galleryImages.length - 1) % galleryImages.length)
                    }
                    onMoveNextRequest={() =>
                        setItemNumber((itemNumber + 1) % galleryImages.length)
                    }
                    animationDisabled={ true }
                />
            )}
        </section>
    )
}

export default AboutInvestitionGallery
