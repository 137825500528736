import React from "react"
import { Fade } from "react-awesome-reveal"

const AboutInvestitionOne = () => (
  <section className="about-investition-one">
    <div className="container about-investition-one__container">
      <div className="row about-investition-one__holder-top container__item container__item--one">
        <div className="col-12 col-lg-6 about-investition-one__image-holder about-investition-one__image-holder--one about-investition-one__image-holder--left">
          <Fade delay={150} direction={"left"} triggerOnce>
            <div
              className="image-holder__image-full image-holder__image-full--one image-shadow"
              style={{
                backgroundImage: `url(${require("../../assets/img/about-investition1.jpg")})`,
              }}
            ></div>
          </Fade>
        </div>
        <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 about-investition-one__content">
          <Fade delay={150} direction={"right"} triggerOnce>
            <p className="h2 semi-bold content__uptitle">KW51</p>
            <h2 className="big mb-40 content__title">
              Strefa zen <br />w sercu Krakowa
            </h2>
            <p className="content__text">
              Budynek mieszkalny KW51 to jedyna w swoim rodzaju inwestycja w
              Krakowie. Doskonała lokalizacja obiektu połączona jest z jego
              unikatowym stylem nawiązującym do koncepcji japońskiego zen. To
              spokojny zakątek sprzyjający wyciszeniu w tętniącym życiem
              mieście.
            </p>
          </Fade>
        </div>
      </div>
      <div className="col-12 col-lg-6 about-investition-one__image-holder about-investition-one__image-holder--first about-investition-one__image-holder--left container__item container__item--two">
        <Fade delay={500} direction={"left"} triggerOnce>
          <img
            data-src={require("../../assets/img/about-investition2.jpg")}
            alt=""
            className="image-holder__image image-shadow lazyload"
          />
        </Fade>
      </div>
      <div className="about-investition-one__holder-middle container__item container__item--three">
        <div className="col-12 col-lg-4 col-xxl-3 offset-xxl-1">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="mb-70 holder-middle__title">
              Klimat <br />
              japońskiego <br />
              zen w sercu <br />
              Krakowa
            </h2>
          </Fade>
          <Fade delay={300} direction={"left"} triggerOnce>
            <p className="holder-middle__text">
              Co wyróżnia KW51? Budynek stylem nawiązuje do klimatu Japonii.
              Wewnątrz budynku znajduje się wydzielona dla mieszkańców
              przestrzeń do jogi i medytacji. To strefa zen dla aktywnych
              mieszkańców drugiego co do wielkości miasta w Polsce.
            </p>
          </Fade>
        </div>
      </div>
      <div className="row about-investition-one__holder-bottom container__item container__item--four">
        <div className="col-12 col-lg-6 col-xxl-4 offset-xxl-1 about-investition-one__bottom-content">
          <Fade delay={150} direction={"left"} triggerOnce>
            <h2 className="bottom-content__title">Zielony dziedziniec</h2>
            <p className="bottom-content__text">
              Zielony dziedziniec w budynku sprzyja relaksowi i tworzy
              harmonijną przestrzeń w samym centrum miasta.
            </p>
          </Fade>
        </div>
        <div className="col-12 col-lg-6 about-investition-one__image-holder about-investition-one__image-holder--right">
          <Fade delay={150} direction={"right"} triggerOnce>
            <div
              className="image-holder__image-full image-holder__image-full--two image-shadow"
              style={{
                backgroundImage: `url(${require("../../assets/img/about-investition3.jpg")})`,
              }}
            ></div>
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default AboutInvestitionOne
