import React from "react"
import { Fade } from "react-awesome-reveal"

const AboutInvestitionThree = () => (
	<section className="about-investition-three">
        <div className="container">
            <div className="row about-investition-three__first-image-holder">
                <div className="col-12 col-lg-7">
                    <Fade delay={150} direction={"left"} triggerOnce>
                        <img data-src={ require('../../assets/img/about-investition7.jpg') } alt="" className="image-shadow lazyload" />
                    </Fade>
                </div>
            </div>
            <div className="row about-investition-three__second-image-holder">
                <div className="col-12 col-lg-6 offset-lg-5">
                    <Fade delay={250} direction={"right"} triggerOnce>
                        <img data-src={ require('../../assets/img/about-investition8.jpg') } alt="" className="image-shadow lazyload" />
                    </Fade>
                </div>
            </div>
            <div className="row about-investition-three__third-image-holder">
                <div className="col-12 col-lg-5 offset-lg-1">
                    <Fade delay={500} direction={"left"} triggerOnce>
                        <img data-src={ require('../../assets/img/about-investition9.jpg') } alt="" className="image-shadow lazyload" />
                    </Fade>
                </div>
            </div>
        </div>
    </section>
)

export default AboutInvestitionThree
