import React from "react"
import { Fade } from "react-awesome-reveal"

const AboutInvestitionTwo = () => (
  <section className="about-investition-two">
    <div className="container">
      <div className="row about-investition-two__holder-top">
        <div className="col-12 col-lg-6 about-investition-two__image-holder holder-top__item holder-top__item--one">
          <Fade delay={150} direction={"left"} triggerOnce>
            <div
              className="image-holder__image-full image-shadow"
              style={{
                backgroundImage: `url(${require("../../assets/img/about-investition4.jpg")})`,
              }}
            ></div>
          </Fade>
        </div>
        <div className="col-12 col-lg-4 col-xxl-3 offset-lg-1 holder-top__text-holder holder-top__item holder-top__item--two">
          <Fade delay={150} direction={"right"} triggerOnce>
            <h2 className="mb-40 text-holder__title">Unikalny design</h2>
            <p className="text-holder__text">
              Projekt inspirowany jest kulturą japońską oraz regułami feng shui.
            </p>
          </Fade>
        </div>
        <div className="col-12 col-lg-5 offset-lg-5 holder-top__image holder-top__item holder-top__item--three">
          <Fade delay={300} direction={"right"} triggerOnce>
            <img
              data-src={require("../../assets/img/about-investition5.jpg")}
              alt=""
              className="image-shadow lazyload"
            />
          </Fade>
        </div>
      </div>
      <div className="row about-investition-two__holder-bottom">
        <div className="col-12 col-lg-4 holder-bottom__image-holder">
          <Fade delay={300} direction={"left"} triggerOnce>
            <img
              data-src={require("../../assets/img/about-investition6.jpg")}
              alt=""
              className="image-shadow lazyload"
            />
          </Fade>
        </div>
        <div className="col-12 col-lg-6 col-xxl-4 offset-lg-2 holder-bottom__content">
          <Fade delay={150} direction={"right"} triggerOnce>
            <h2 className="mb-70 content__title">Work&life balance</h2>
          </Fade>
          <Fade delay={250} direction={"right"} triggerOnce>
            <p className="content__text">
              Budynek KW51 powstał z myślą o aktywnych mieszkańcach miasta, którzy
              pomimo dynamicznego trybu życia cenią równowagę. Nowoczesny design i
              nawiązania do stylu życia ,,zen’’ to kombinacja na miarę potrzeb
              świadomych nabywców. Zielona okolica sprzyja aktywnemu wypoczynkowi
              po pracy, a bliska odległość od Starego Miasta spotkaniom
              towarzyskim i rozrywce w wolnych chwilach.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  </section>
)

export default AboutInvestitionTwo
