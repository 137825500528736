import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import AboutInvestitionOne from "../components/aboutInvestition/aboutInvestitionOne"
import AboutInvestitionTwo from "../components/aboutInvestition/aboutInvestitionTwo"
import AboutInvestitionThree from "../components/aboutInvestition/aboutInvestitionThree"
import AboutInvestitionGallery from "../components/aboutInvestition/aboutInverstitionGallery"

const ForInvestors = () => (
	<Layout>
		<SEO title="O inwestycji" />
        <Banner title="O inwestycji" />
        <AboutInvestitionOne />
		<AboutInvestitionTwo />
		<AboutInvestitionThree />
		<AboutInvestitionGallery />
	</Layout>
)

export default ForInvestors
